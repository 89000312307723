
jQuery(function() {

  $link = jQuery('#scroll-to-top-link');
  $body = jQuery('body, html');

  $link.on('click', function(e){

    e.preventDefault();

    $body.animate({ scrollTop: 0}, 700);

    return false;

  });

});
